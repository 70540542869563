<template>
  <div>
<!--    <div class="crumbs">-->
<!--      <el-breadcrumb separator="/">-->
<!--        <el-breadcrumb-item>-->
<!--          <i class="el-icon-lx-cascades"></i> 菜单管理-->
<!--        </el-breadcrumb-item>-->
<!--      </el-breadcrumb>-->
<!--    </div>-->
    <div style="padding: 20px">
      <div class="handle-box">
        <!--        <el-button
                    type="primary"
                    icon="el-icon-delete"
                    class="handle-del mr10"
                    @click="delAllSelection"
                >批量删除
                </el-button>-->
        <el-button type="primary" class="handle-del mr10" @click="addVisible = true">新增菜单</el-button>
        <el-select v-model="query.type" placeholder="类别" class="handle-select mr10">
          <el-option key="0" label="全部" value="0"></el-option>
          <el-option key="1" label="横向" value="x"></el-option>
          <el-option key="2" label="纵向" value="y"></el-option>
        </el-select>

        <el-input v-model="query.menuName" placeholder="菜单名称" class="handle-select mr10"></el-input>

        <el-button type="primary" @click="handleSearch">搜索</el-button>

        <el-button type="info" @click="reset">重置</el-button>

      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          :row-style="{height:10+'px'}"
          :cell-style="{padding:6+'px'}"
          @selection-change="handleSelectionChange"
      >
        <!--        <el-table-column type="selection" width="55" align="center"></el-table-column>-->
        <el-table-column prop="type" label="类型(x横向，y纵向)" align="center"></el-table-column>
        <el-table-column prop="sort" label="排序" width="100" align="center"></el-table-column>
        <el-table-column prop="words" label="菜单名称"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="url" label="路径"></el-table-column>

        <el-table-column label="图标">
          <template  v-slot="scope">
            <el-image
                class="code"
                :src="scope.row.srcimage"
            >
              <template #error>
                <div class="image-slot">
                  <span class="demonstration">暂无图片</span>
                </div>
              </template>
            </el-image>
          </template>
        </el-table-column>


        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <!--编辑按钮-->
            <el-button @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <!--删除按钮-->
            <el-button @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="修改菜单" v-model="editVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="菜单名称">
          <el-input v-model="form.words"></el-input>
        </el-form-item>
        <el-form-item label="路径">
          <el-input v-model="form.url"></el-input>
        </el-form-item>

        <el-form-item label="图标">
          <el-input v-model="form.srcimage"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>

        <el-form-item label="菜单类别">
          <el-select v-model="form.type" placeholder="类别" class="handle-select mr10">
            <el-option key="1" label="横向" value="x"></el-option>
            <el-option key="2" label="纵向" value="y"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="添加时间">
          <el-input disabled v-model="form.createTime"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="qx">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
      </template>
    </el-dialog>

    <!-- 新增弹出框框 -->
    <el-dialog title="新增菜单" v-model="addVisible" width="30%">
      <el-form :rules="rules" ref="ruleForm" :model="addForm" label-width="100px">
        <el-form-item prop="words" label="菜单名称">
          <el-input v-model="addForm.words"></el-input>
        </el-form-item>
        <el-form-item label="路径">
          <el-input v-model="addForm.url"></el-input>
        </el-form-item>

        <el-form-item label="图标">
          <el-input v-model="addForm.srcimage"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="addForm.sort"></el-input>
        </el-form-item>

        <el-form-item prop="type" label="菜单类别">
          <el-select v-model="addForm.type" placeholder="类别" class="handle-select mr10">
            <el-option key="1" label="横向" value="x"></el-option>
            <el-option key="2" label="纵向" value="y"></el-option>
          </el-select>
        </el-form-item>



      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd('ruleForm')">确 定</el-button>
                </span>
      </template>
    </el-dialog>


  </div>
</template>

<script>
import {list,edit,addMenu,delMenu} from "@/api/menu";

export default {
  name: "Menus",
  data() {
    return {
      query: {
        keyword: "",
        regionId: "",
        campusId: "",
        pageIndex: 1,
        pageSize: 10
      },
      rules: {
        words: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        type: [
          {required: true, message: '请选择类型', trigger: 'change'}
        ]
      },
      regionList: [],
      campusList: [],
      campusList1: [],
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      form: {},
      addForm: {},
      idx: -1,
      id: -1
    };
  },
  created() {
    this.getData();
    // 城市下拉数据
  },
  methods: {
    // 获取数据
    getData() {
      // 表格数据
      list(this.query).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.pageTotal = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      }).catch(() => {
      })
    },

    // 触发搜索按钮
    handleSearch() {
      this.query.pageIndex = 1
      this.getData();
    },
    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除: " + row.words + " 吗?", "提示", {
        type: "warning"
      }).then(() => {
        delMenu(row.id).then(res => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.tableData.splice(index, 1);
          } else {
            this.$message.error("删除失败");
          }
        })

      }).catch(() => {
      });
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    //取消编辑
    qx() {
      this.editVisible = false;
      this.$message.info(`您取消了修改`);
    },
    // 保存编辑
    saveEdit() {
      edit(JSON.parse(JSON.stringify(this.form))).then(res => {
        this.editVisible = false;
        if (res.code == 200) {
          if (res.data > 0) {
            this.getData()
            this.$message.success(`修改菜单: ${this.form.words} 成功`);
          } else {
            this.$message.error(`修改菜单: ${this.form.words} 失败`);
          }
        } else {
          this.$message.error(`修改菜单: ${this.form.words} 失败`);
        }
      })

    },

    /**
     * 新增
     */
    saveAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addMenu(JSON.parse(JSON.stringify(this.addForm))).then(res => {
            this.addVisible = false;
            console.log(res);
            if (res.code == 200) {
              if (res.data > 0) {
                this.getData()
                this.$message.success(`新增菜单: ${this.addForm.words} 成功`);
              } else {
                this.$message.error(`新增菜单: ${this.addForm.words} 失败`);
              }
            } else {
              this.$message.error(`新增菜单: ${this.addForm.words} 失败`);
            }
          })
        }
      })
    },

    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    },
    // 重置
    reset() {
      this.query.menuName = ""
      this.query.type = ""
      this.query.keyword = ""
      this.query.pageIndex = 1
      this.getData()
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.code {
  margin-top: 6px;
  width: 30px;
  height: 30px;
  border-radius: 6px;

}
</style>
